<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.keyword"
        placeholder="请输入关键字"
        style="width: 200px"
        class="filter-item"
        clearable
      />
      <el-cascader
        class="filter-item"
        v-model="listQuery.roles"
        placeholder="角色"
        :props="queryRolesProps"
        :options="queryRoleOptionList"
        style="width:300px"
        :show-all-levels="false"
        clearable
      ></el-cascader>
      <el-select
        v-model="listQuery.isForbidden"
        placeholder="是否冻结"
        style="width: 120px"
        class="filter-item"
        clearable
      >
        <el-option label="正常" :value="'0'" />
        <el-option label="冻结" :value="'1'" />
      </el-select>
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >搜索</el-button
      >
      <el-button
        class="filter-item"
        type="primary"
        @click="checkDialogFormVisible(true, {})"
        >新增</el-button
      >
    </div>

    <div style="margin-bottom: 5px">用户数量:{{ total }}</div>

    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      size="small"
      border
      fit
      highlight-current-row
    >
      <el-table-column
        align="center"
        prop="id"
        label="ID"
        width="95"
      ></el-table-column>
      <el-table-column
        label="昵称"
        min-width="80"
        align="center"
        prop="nickname"
      ></el-table-column>
      <el-table-column
        label="账号"
        min-width="80"
        align="center"
        prop="username"
      ></el-table-column>
      <el-table-column
        label="角色"
        min-width="180"
        align="center"
        prop="roless"
      >
        <template slot-scope="scope">
          {{ scope.row.roless.join(" | ") }}
        </template>
      </el-table-column>
      <el-table-column
        class-name="status-col"
        label="用户状态"
        min-width="60"
        align="center"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.is_forbidden === 0" type="primary"
            >正常</el-tag
          >
          <el-tag v-else-if="scope.row.is_forbidden === 1" type="warning"
            >冻结</el-tag
          >
          <el-tag v-else>后端无字段</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="最后登录时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" v-show="scope.row.last_login_at !== null" />
          <span>{{ formatApiDate(scope.row.last_login_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="创建时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.created_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="created_at"
        label="修改时间"
        min-width="200"
      >
        <template slot-scope="scope">
          <i class="el-icon-time" />
          <span>{{ formatApiDate(scope.row.updated_at) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="warning"
            size="mini"
            @click="checkDialogFormVisible(true, scope.row)"
            >编辑</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="checkResetPwdDialogVisible(true, scope.row)"
            >重置密码</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!--    编辑和新增-->
    <el-dialog
      :title="DIALOG_TITLE_TYPE[dialogStatus]"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="form"
        :rules="rules[dialogStatus]"
        :model="form"
        label-position="left"
        label-width="150px"
        style="width: 80%; margin-left:50px;"
      >
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入" />
        </el-form-item>
        <el-form-item label="账户" prop="account">
          <el-input v-model="form.account" placeholder="请输入" />
        </el-form-item>
        <el-form-item
          label="密码"
          prop="password"
          v-show="dialogStatus === 'CREATE'"
        >
          <el-input v-model="form.password" type="text" placeholder="请输入" />
        </el-form-item>
        <el-form-item
          label="再次确认密码"
          prop="verifyPassword"
          v-show="dialogStatus === 'CREATE'"
        >
          <el-input
            v-model="form.verifyPassword"
            type="text"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-cascader
            class="filter-item"
            v-model="form.roles"
            placeholder="请选择角色"
            :props="rolesProps"
            :options="roleOptionList"
            style="width:100%"
          ></el-cascader>
        </el-form-item>
        <el-form-item
          label="是否冻结"
          prop="isForbidden"
          v-show="dialogStatus === 'UPDATE'"
        >
          <el-select
            v-model="form.isForbidden"
            placeholder="请选择状态"
            style="width:100%"
            class="filter-item"
            clearable
          >
            <el-option
              v-for="item in IS_FORBIDDEN"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkDialogFormVisible(false)">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <!--    重置密码-->
    <el-dialog
      title="重置密码"
      :visible.sync="resetPwdDialogVisible"
      :close-on-click-modal="false"
    >
      <el-form
        ref="resetPwdForm"
        :rules="resetPwdRules"
        :model="resetPwdForm"
        label-position="left"
        label-width="150px"
        style="width: 400px; margin-left:50px;"
      >
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="resetPwdForm.password"
            type="text"
            placeholder="请输入"
          />
        </el-form-item>
        <el-form-item label="再次确认密码" prop="verifyPassword">
          <el-input
            v-model="resetPwdForm.verifyPassword"
            type="text"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="checkResetPwdDialogVisible(false, {})"
          >取消</el-button
        >
        <el-button type="primary" @click="saveResetPwd" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getBackGroundUserList,
  createUser,
  updateUser,
  resetPassword
} from "@/api/user";
import { getCascadeRole } from "@/api/role";
import { DIALOG_TITLE_TYPE, IS_FORBIDDEN } from "@/utils/enumUtile";
import md5 from "js-md5";
import { encapsulationMenus, splitMenus, formatApiDate } from "@/utils/utils";

export default {
  computed: {
    DIALOG_TITLE_TYPE() {
      return DIALOG_TITLE_TYPE;
    },
    IS_FORBIDDEN() {
      return IS_FORBIDDEN;
    },
    formatApiDate() {
      return formatApiDate;
    }
  },
  components: {},
  filters: {},
  data() {
    const validatePass = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能小于6位"));
      } else {
        if (this.form.verifyPassword !== "") {
          this.$refs.form.validateField("verifyPassword");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能小于6位"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        keyword: "",
        roles: "",
        isForbidden: ""
      },
      queryRolesProps: {
        multiple: false,
        value: "id",
        label: "name",
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.cascaderLazyLoad
      }, // 查询条件的角色
      queryRoleOptionList: [], // 查询条件的角色
      dialogFormVisible: false, // 新增 or 编辑的弹框
      dialogStatus: "",
      rules: {
        CREATE: {
          account: [
            { required: true, message: "账户不能为空", trigger: "change" }
          ],
          password: [
            { required: true, trigger: "blur", validator: validatePass }
          ],
          verifyPassword: [
            { required: true, trigger: "blur", validator: validatePass2 }
          ],
          name: [
            { required: true, message: "姓名不能为空", trigger: "change" }
          ],
          roles: [
            { required: true, message: "角色不能为空", trigger: "change" }
          ]
        },
        UPDATE: {
          account: [
            { required: true, message: "账户不能为空", trigger: "change" }
          ],
          name: [
            { required: true, message: "姓名不能为空", trigger: "change" }
          ],
          roles: [
            { required: true, message: "角色不能为空", trigger: "change" }
          ],
          isForbidden: [
            { required: true, message: "是否冻结不能为空", trigger: "change" }
          ]
        }
      },
      form: {
        id: "",
        account: "",
        password: "",
        verifyPassword: "",
        name: "",
        roles: [],
        isForbidden: ""
      },
      btnLoading: false,
      roleOptionList: [], //角色
      editObj: {}, // 修改的对象
      rolesProps: {
        multiple: true,
        value: "id",
        label: "name",
        checkStrictly: true,
        lazy: true,
        lazyLoad: this.cascaderLazyLoad
      },
      resetPwdDialogVisible: false, // 重置密码
      resetPwdRules: {
        password: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length < 6) {
                callback(new Error("密码不能小于6位"));
              } else {
                if (this.resetPwdForm.verifyPassword !== "") {
                  this.$refs.resetPwdForm.validateField("verifyPassword");
                }
                callback();
              }
            }
          }
        ],
        verifyPassword: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value.length < 6) {
                callback(new Error("密码不能小于6位"));
              } else if (value !== this.resetPwdForm.password) {
                callback(new Error("两次输入密码不一致!"));
              } else {
                callback();
              }
            }
          }
        ]
      }, // 重置密码的条件判断
      resetPwdForm: {
        password: "",
        verifyPassword: ""
      }
    };
  },
  created() {
    this.getList();
    this.getRoleOption(0);
  },
  watch: {},
  methods: {
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    /**
     * 查询用户列表
     */
    getList() {
      this.listLoading = true;
      const form = this.listQuery;
      const params = {
        ...form
      };

      if (form.roles) {
        const resultArr = [];
        encapsulationMenus(form.roles, resultArr);
        params.roles = resultArr.length !== 0 ? resultArr.join("") : "";
      }
      getBackGroundUserList(params)
        .then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
        })
        .finally(() => {
          this.listLoading = false;
        });
    },
    /**
     * 查询角色
     * @param parentId
     */
    getRoleOption(parentId) {
      const params = {
        parentId
      };
      getCascadeRole(params).then(resp => {
        this.roleOptionList = resp.data;
        this.queryRoleOptionList = resp.data;
      });
    },
    /**
     * 切换状态
     * @param status
     * @param editObj
     */
    checkDialogFormVisible(status, editObj = {}) {
      this.dialogFormVisible = status;
      this.editObj = editObj;
      if (status) {
        this.dialogStatus =
          editObj && Object.keys(editObj).length !== 0 ? "UPDATE" : "CREATE";
      } else {
        this.dialogStatus = "";
      }
      if (editObj && Object.keys(editObj).length !== 0) {
        this.form.id = editObj.id;
        this.form.account = editObj.username;
        this.form.name = editObj.nickname;
        this.form.isForbidden = editObj.is_forbidden;
        this.form.roles = splitMenus(editObj.roles);
      } else {
        this.form.id = "";
        this.form.account = "";
        this.form.name = "";
        this.form.isForbidden = "";
      }
      this.$validator.reset();
    },

    /**
     * 确认添加还是编辑
     */
    saveData() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const form = this.form;
          const resultArr = [];
          encapsulationMenus(form.roles, resultArr);
          const params = {
            account: form.account,
            password: md5(form.password),
            name: form.name,
            roles: JSON.stringify(resultArr)
          };
          this.btnLoading = true;
          const dialogStatus = this.dialogStatus;
          if (dialogStatus === "CREATE") {
            createUser(params)
              .then(() => {
                this.getList();
                this.checkDialogFormVisible(false, {});
                this.$notify({
                  title: "成功",
                  message: "保存成功",
                  type: "success",
                  duration: 2000
                });
              })
              .finally(() => {
                this.btnLoading = false;
              });
          } else if (dialogStatus === "UPDATE") {
            params.id = form.id;
            params.isForbidden = form.isForbidden;
            updateUser(params)
              .then(() => {
                this.getList();
                this.checkDialogFormVisible(false, {});
                this.$notify({
                  title: "成功",
                  message: "修改成功",
                  type: "success",
                  duration: 2000
                });
              })
              .finally(() => {
                this.btnLoading = false;
              });
          }
        }
      });
    },
    /**
     * 选中角色获取的值
     * @param node
     * @param resolve
     */
    cascaderLazyLoad(node, resolve) {
      if (!node.root) {
        const params = {
          parentId: node.value || 0
        };
        getCascadeRole(params).then(resp => {
          const data = resp.data;
          resolve(data);
        });
      }
    },
    /**
     * 切换内容
     * @param status
     * @param resetObj
     */
    checkResetPwdDialogVisible(status, resetObj = {}) {
      this.resetPwdDialogVisible = status;
      if (status) {
        this.resetPwdForm.id = resetObj.id;
      } else {
        this.resetPwdForm.id = 0;
        this.resetPwdForm.password = "";
        this.resetPwdForm.verifyPassword = "";
      }
    },
    /**
     * 确定重置密码
     */
    saveResetPwd() {
      this.$refs["resetPwdForm"].validate(valid => {
        if (valid) {
          this.btnLoading = true;
          const form = this.resetPwdForm;
          const params = {
            id: form.id,
            password: md5(form.password)
          };
          resetPassword(params)
            .then(() => {
              this.checkResetPwdDialogVisible(false, {});
              this.getList();
              this.$notify({
                title: "成功",
                message: "密码重置成功",
                type: "success",
                duration: 2000
              });
            })
            .finally(() => {
              this.btnLoading = false;
            });
        }
      });
    }
  }
};
</script>
<style scoped lang="scss"></style>
